import * as React from "react";
import { useState, useEffect } from "react";
// material
import { Container, Typography, Button, TextField, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// components
import Page from "../components/Page";
import { RewardList } from "../components/_dashboard/rewards";
import { API_KEY, BACKEND_URL } from "../config/auth";
//
import REWARDS from "../_mocks_/rewards";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Rewards() {
  const [rewards, setRewards] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [points, setPoints] = useState([]);
  const navigate = useNavigate();

  const products = localStorage.getItem("delight-dashboard-products");
  const hasDirectRewards = JSON.parse(products)?.directRewards;

  const getRewards = async () => {
    try {
      const token = localStorage.getItem("delight-dashboard-token");
      const store = localStorage.getItem("delight-dashboard-store");
      if (token && store) {
        const headers = {
          "Content-type": "application/json",
          Authorization: `bearer ${API_KEY}`,
          store,
        };
        const res = await fetch(
          `${BACKEND_URL}/api/dashboard/rewards/${token}`,
          {
            headers,
            method: "GET",
          }
        );
        if (res.status === 200) {
          const data = await res.json();
          setRewards(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        window.location.href = "/login";
      }
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };
  const toAddRewards = () => {
    navigate("/dashboard/rewards/add-reward", { replace: true });
  };

  const getPoints = async () => {
    try {
      const store = localStorage.getItem("delight-dashboard-store");
      if (store) {
        const headers = {
          "Content-type": "application/json",
          Authorization: `bearer ${API_KEY}`,
          store,
        };

        const res = await fetch(`${BACKEND_URL}/api/dashboard/get_points`, {
          headers,
          method: "GET",
        });

        if (res.status === 200) {
          const data = await res.json();
          setPoints(data.points);
        } else {
          console.error("Failed to fetch points");
        }
      } else {
        console.error("Store not found");
      }
    } catch (e) {
      console.error("Error", e);
    }};

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    try {
      const store = localStorage.getItem("delight-dashboard-store");
      if (store) {
        const headers = {
          "Content-type": "application/json",
          Authorization: `bearer ${API_KEY}`,
        };

        const body = JSON.stringify({ store, points });

        const res = await fetch(`${BACKEND_URL}/api/dashboard/store_points`, {
          headers,
          method: "PUT",
          body,
        });

        if (res.status === 200) {
          const data = await res.json();
          setLoading(false);
          handleClose();
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        window.location.href = "/login";
      }
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };

  useEffect(() => {
    getRewards();
    getPoints();
  }, []);
  

  return (
    <Page title="Dashboard: Rewards | Delight">
      <Container>
        <Typography variant="h4">Rewards Settings</Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="p">
            Define Rewards Points Accumulation Rate
          </Typography>
          <div style={{ margin: "20px 10px" }}>
            <TextField
              fullWidth
              label="Points Per $ spent"
              id="fullWidth"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
          </div>
          <Button variant="outlined" onClick={handleSubmit}>
            Update
          </Button>
        </div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="p">Define Rewards</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={toAddRewards}>
              Add Rewards
            </Button>
          </Grid>
        </Grid>
        <div style={{ margin: "20px 0px 10px 0px" }}>
          <Typography variant="h6">Current Rewards</Typography>
        </div>
        <RewardList
          rewards={isLoading ? REWARDS : rewards}
          hasDirectRewards={hasDirectRewards}
        />
      </Container>
    </Page>
  );
}
