import { useState, useEffect } from "react";
// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppReviews,
  AppReferrals,
  AppRewards,
  AppAverageRating,
  AppWebsiteStats,
} from "../components/_dashboard/app";
import { API_KEY, BACKEND_URL, ENCRYPTION_KEY } from "../config/auth";
import CryptoJS from "crypto-js";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [chartData, setChartData] = useState([]);
  const [monthlyData, setMonthlyData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const getChartData = async () => {
    try {
      const token = localStorage.getItem("delight-dashboard-token");
      const store = localStorage.getItem("delight-dashboard-store");
      if (token && store) {
        const headers = {
          "Content-type": "application/json",
          Authorization: `bearer ${API_KEY}`,
          store,
        };
        const res = await fetch(
          `${BACKEND_URL}/api/dashboard/usage/count/${token}`,
          {
            headers,
            method: "GET",
          }
        );
        if (res.status === 200) {
          const data = await res.json();
          setChartData(data);
        }
      } else {
        window.location.href = "/login";
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getMonthlyData = async () => {
    try {
      const token = localStorage.getItem("delight-dashboard-token");
      const store = localStorage.getItem("delight-dashboard-store");
      if (token && store) {
        const headers = {
          "Content-type": "application/json",
          Authorization: `bearer ${API_KEY}`,
          store,
        };
        const res = await fetch(
          `${BACKEND_URL}/api/dashboard/usage/month/${token}`,
          {
            headers,
            method: "GET",
          }
        );
        if (res.status === 200) {
          const data = await res.json();
          setMonthlyData(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        window.location.href = "/login";
      }
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };

  useEffect(() => {
    const saveQueryParamsToLocalStorage = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const query = Object.fromEntries(urlSearchParams.entries());
      try {
        const decryptedString = CryptoJS.AES.decrypt(
          query.encryptedParams,
          ENCRYPTION_KEY
        ).toString(CryptoJS.enc.Utf8);

        const queryParams = JSON.parse(decryptedString);

        console.log(queryParams);

        // Define a mapping from query parameter names to local storage keys
        const keyMapping = {
          userToken: "delight-dashboard-token",
          store: "delight-dashboard-store",
          storeName: "delight-dashboard-store-name",
          image: "delight-dashboard-store-image",
          email: "delight-dashboard-email",
          userName: "delight-dashboard-user-name",
          "products[loyalty]": "delight-dashboard-products-loyalty",
          "products[referrals]": "delight-dashboard-products-referrals",
          "products[reviews]": "delight-dashboard-products-reviews",
          "products[directRewards]":
            "delight-dashboard-products-direct-rewards",
        };

        // Iterate through query parameters, map to local storage keys, and save
        Object.keys(queryParams).forEach((param) => {
          const localStorageKey = keyMapping[param];
          if (queryParams[param] === "null") {
            queryParams[param] = null;
          }
          if (localStorageKey && queryParams[param]) {
            localStorage.setItem(localStorageKey, queryParams[param]);
          }
        });
        // Rest of the decryption process...
      } catch (error) {
        console.error("Error during decryption:", error);
      }
    };
    // Call the function to save query parameters on component mount
    saveQueryParamsToLocalStorage();

    getChartData().then(() => {
      getMonthlyData();
    });
  }, []);

  return (
    <Page title="Dashboard | Delight">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppReviews data={monthlyData?.reviews} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppReferrals data={monthlyData?.referrals} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppRewards data={monthlyData?.rewards} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppAverageRating data={monthlyData?.averageRating} />
          </Grid>

          <Grid item xs={16} md={10} lg={12}>
            {!isLoading && <AppWebsiteStats chartData={chartData} />}
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
