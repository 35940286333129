import React, { useState } from "react";
import { Button, Box, Typography, Modal } from "@mui/material";
import { BACKEND_URL, API_KEY } from "../../config/auth";
import { TailSpin } from 'react-loader-spinner';
import { useImage } from "src/ImageContextProvider";

const UploadImage = () => {
  const { imageUpload, updateImageUpload } = useImage();
  const [imageFile, setImageFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        setImageFile(imageDataUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true); 

      const key = localStorage.getItem("delight-dashboard-store");
      const requestBody = {
        key: key,
        image: imageFile,
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${BACKEND_URL}/api/dashboard/upload_business_image`,
        requestOptions
      );

      if (response.status === 200) {
        console.log("Image Uploaded successfully");
        const responseImage = await response.json();
        const imageUrl = responseImage.image;
        localStorage.setItem("delight-dashboard-store-image", imageUrl);
        updateImageUpload(imageUrl);
        handleClose();
      } else if (response.status === 400) {
        console.error("Image Not Upload: Bad request");
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Upload Image
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Image
          </Typography>
          <div style={{ margin: "20px 0" }}>
            <input
              fullWidth
              type="file"
              id="fullWidth"
              name="upload-photo"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button variant="outlined" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <TailSpin height={20} width={20} color="#000" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UploadImage;
