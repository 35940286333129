import * as React from "react";
import { useState, useEffect } from "react";
// components
import Page from "../components/Page";
import { Typography, Container, Button, Grid } from "@mui/material";
import UploadImage from "../layouts/dashboard/UploadImage";
import Switch from "@mui/material/Switch";
import { API_KEY, BACKEND_URL } from "../config/auth";
import { useImage } from "src/ImageContextProvider";
import { Avatar } from "@mui/material";

export default function StoreSetting() {
  const { imageUpload } = useImage();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [referralButtonProductPage, setReferralButtonProductPage] =
    useState(false);
  const [referralButtonThankYouPage, setReferralButtonThankYouPage] =
    useState(false);

  const image = localStorage.getItem("delight-dashboard-store-image");

  const key = localStorage.getItem("delight-dashboard-store");

  const notify = () => {
    alert("Succuss!");
  };

  useEffect(() => {
    const requestBody = JSON.stringify({
      storeName: key,
    });
    fetch(`${BACKEND_URL}/api/dashboard/is-thankyou-button-enabled`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        setReferralButtonThankYouPage(data.isThankyouButtonEnabled);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const requestBody = JSON.stringify({
      storeName: key,
    });
    fetch(`${BACKEND_URL}/api/dashboard/is-product-button-enabled`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        setReferralButtonProductPage(data.isProductButtonEnabled);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleProductPageSwitchChange = (event) => {
    const status = event.target.checked;
    setReferralButtonProductPage(status);
    makeProductPageRequest(key, status);
  };

  const handleThankYouPageSwitchChange = (event) => {
    const status = event.target.checked;
    setReferralButtonThankYouPage(status);
    makeThankYouPageRequest(key, status);
  };

  const makeProductPageRequest = (key, status) => {
    const requestBody = JSON.stringify({
      key,
      status,
    });

    fetch(`${BACKEND_URL}/api/dashboard/show-product-button`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        notify();
        console.log("Success");
      })
      .catch((error) => {
        // Handle error
      });
  };

  const makeThankYouPageRequest = (key, status) => {
    const requestBody = JSON.stringify({
      key,
      status,
    });

    fetch(`${BACKEND_URL}/api/dashboard/show-thankyou-button`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        notify();
        console.log("Success", data);
      })
      .catch((error) => {
        // Handle error
      });
  };

  return (
    <Page title="Dashboard: Store Settings | Delight">
      <Container>
        <Typography variant="h4" gutterBottom>
          Store Settings
        </Typography>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="p" gutterBottom>
              Store profile Image
            </Typography>
          </Grid>
          <Grid item></Grid>
          <Grid item>
            {image && imageUpload ? (
              <img
                src={imageUpload || image}
                alt="photoUrl"
                style={{ width: "100px", height: "auto" }}
              />
            ) : (
              <Avatar />
            )}
          </Grid>
          <Grid item>
            <UploadImage />
          </Grid>
        </Grid>

        <div style={{ marginTop: "20px" }}>
          <Typography variant="h4" gutterBottom>
            Referral Settings
          </Typography>
        </div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="p" gutterBottom>
              Referral Button -{" "}
              <span style={{ color: "Red" }}>Product Page</span>
            </Typography>
          </Grid>
          <div style={{ marginLeft: "15px" }}></div>
          <Grid item>
            <Switch
              {...label}
              onChange={handleProductPageSwitchChange}
              checked={referralButtonProductPage}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: "5px" }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="p" gutterBottom>
                Referral Button -{" "}
                <span style={{ color: "Blue" }}>Thank You Page</span>
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                {...label}
                onChange={handleThankYouPageSwitchChange}
                checked={referralButtonThankYouPage}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
}
