import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const reviews = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  stars: sample([1, 2, 3, 4, 5]),
  user: {
    firstName: faker.name.findName(),
    email: 'support@delightrewards.com'
  },
  comment: sample([
    `Product, ordering, time until delivery were all top notch.
  Will be ordering more for myself and friends`,
    "Amazing smelling & tasting tea! I'm absolutely hooked! Excited to try the rest of them! The owner is absolutely wonderful too!",
    'Awesome tea and service',
    `The tea is amazing. The packing is hysterical - read every word! (I now spend my spare time thinking of funny names for tea.) I've tried several blends and have not been disappointed with any. I'm waiting to bust out a bag of "Sorry to Hear Your Such a (girl part) Tea" for the next person who sneezes in my office! I love this business and Blendbee, too. You guys are great! Wishing you all the best in 2020 - I'll be visiting often. :)`
  ]),
  verified: sample(['active', 'inactive']),
  createdAt: sample(['Feb 25, 2022', 'Feb 26, 2022', 'Feb 27, 2022', 'Feb 28, 2022'])
}));

export default reviews;
