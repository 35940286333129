// import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import LoadingTable from '../components/LoadingTable';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import NoTableData from '../components/NoTableData';
import { RedeemListHead, RedeemMoreMenu } from '../components/_dashboard/redeem';
import { API_KEY, BACKEND_URL } from '../config/auth';
import { fDateTimeSuffix } from '../utils/formatTime';
//
import REWARDLIST from '../_mocks_/redeem';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'reward', label: 'Reward', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.comment.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function Redeem() {
  const [rewards, setRewards] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const getUserRewards = async () => {
    try {
      const token = localStorage.getItem('delight-dashboard-token');
      const store = localStorage.getItem('delight-dashboard-store');
      if (token && store) {
        const headers = {
          'Content-type': 'application/json',
          Authorization: `bearer ${API_KEY}`,
          store
        };
        const res = await fetch(`${BACKEND_URL}/api/dashboard/redeem/${token}`, {
          headers,
          method: 'GET'
        });
        if (res.status === 200) {
          const data = await res.json();
          setRewards(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        window.location.href = '/login';
      }
    } catch (e) {
      setLoading(false);
      console.log('Error', e);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const selected = isLoading ? REWARDLIST : rewards;
    if (event.target.checked) {
      const newSelecteds = selected.map((n) => n.comment);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, comment) => {
  //   const selectedIndex = selected.indexOf(comment);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, comment);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - isLoading ? REWARDLIST.length : rewards.length)
      : 0;

  // const filteredRewards = applySortFilter(
  //   isLoading ? REWARDLIST : rewards,
  //   getComparator(order, orderBy),
  //   filterName
  // );
  const filteredRewards = isLoading ? REWARDLIST : rewards;

  const isRewardNotFound = filteredRewards.length === 0 && rewards.length > 0;

  const noRewardsAvailable = !isRewardNotFound && rewards.length === 0;

  useEffect(() => {
    getUserRewards();
  }, []);

  return (
    <Page title="Rewards Redeemed | Delight">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Rewards Redeemed
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New Reward
          </Button> */}
        </Stack>

        <Card>
          {/* <RedeemListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {isLoading ? (
            <LoadingTable />
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <RedeemListHead
                      order={order}
                      // orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={isLoading ? REWARDLIST.length : rewards.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredRewards
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            id,
                            reward,
                            user: { firstName: name, email },
                            createdAt,
                            rewardStatus
                          } = row;
                          const isItemSelected = selected.indexOf(reward.title) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox" />
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={name} src={reward.image} />
                                  <Typography variant="subtitle2" noWrap>
                                    {reward.title}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{reward.points}</TableCell>
                              <TableCell align="left">{reward.type || 'physical'}</TableCell>
                              <TableCell align="left">{fDateTimeSuffix(createdAt)}</TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={(rewardStatus === 0 && 'warning') || 'success'}
                                >
                                  {sentenceCase(rewardStatus === 0 ? 'pending' : 'fulfilled')}
                                </Label>
                              </TableCell>

                              {/* <TableCell align="right">
                            <RedeemMoreMenu />
                          </TableCell> */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isRewardNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {noRewardsAvailable && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                            <NoTableData type="rewards" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={isLoading ? REWARDLIST.length : rewards.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
