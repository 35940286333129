import React, { useState, useEffect } from "react";
import Page from "../components/Page";
import { Container, Typography, TextField, Button, Grid } from "@mui/material";
import { BACKEND_URL, API_KEY } from "../config/auth";
import { useNavigate } from "react-router-dom";

const AddRewards = () => {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [points, setPoints] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        setImageFile(imageDataUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const today = new Date();
    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);

    const formattedDate = nextMonth.toISOString().split("T")[0];

    setExpiryDate(formattedDate);
  }, []);

  const handleSubmit = async () => {
    try {
      const key = localStorage.getItem("delight-dashboard-store");

      if (!title || !points || !imageFile || !value) {
        window.alert("Please fill in all required fields.");
        return;
      }

      const requestBody = {
        key: key,
        backGroundColor: "white",
        color: "white",
        points: points,
        title: title,
        value: value,
        image: imageFile,
        type: "code",
        expiresAt: expiryDate,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${BACKEND_URL}/api/dashboard/add_reward`,
        requestOptions
      );

      if (response.status === 201) {
        console.log("Reward created successfully");
        navigate("/dashboard/rewards", { replace: true });
        window.alert("Request was successful");
        // Handle the response here if needed.
      } else if (response.status === 400) {
        console.error("Reward not created: Bad request");
      } else {
        console.error("Failed to create reward");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Page title="Add Rewards | Delight">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Add Rewards
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigate("/dashboard/rewards")}
              >
                Back
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Title"
              id="fullWidth"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Value"
              id="fullWidth"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Points"
              id="fullWidth"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Expiry Date"
              type="date"
              id="expiryDate"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              name="upload-photo"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSubmit}
              style={{ width: "20%", height: "120%" }}
            >
              Create Reward
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddRewards;
