const API_KEY =
  process.env.REACT_APP_ENV === "PROD"
    ? process.env.REACT_APP_PROD_API_KEY
    : process.env.REACT_APP_DEV_API_KEY;
const BACKEND_URL =
  process.env.REACT_APP_ENV === "PROD"
    ? process.env.REACT_APP_PROD_BACKEND_URL
    : process.env.REACT_APP_DEV_BACKEND_URL;

const ENCRYPTION_KEY =
  process.env.REACT_APP_ENV === "PROD"
  ? process.env.REACT_APP_ENCRYPTION_KEY
    : process.env.REACT_APP_ENCRYPTION_KEY;

export { API_KEY, BACKEND_URL, ENCRYPTION_KEY };
