import React, { createContext, useContext, useState, useEffect } from "react";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [imageUpload, setImageUpload] = useState(() => {
    // Try to get the image from localStorage on component mount
    const storedImage = localStorage.getItem("imageUpload");
    return storedImage ? JSON.parse(storedImage) : null;
  });

  const updateImageUpload = (newImage) => {
    setImageUpload(newImage);
  };

  useEffect(() => {
    // Save the image to localStorage whenever it changes
    localStorage.setItem("imageUpload", JSON.stringify(imageUpload));
  }, [imageUpload]);

  return (
    <ImageContext.Provider value={{ imageUpload, updateImageUpload }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = () => {
  return useContext(ImageContext);
};
