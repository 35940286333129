import faker from 'faker';
import { sample } from 'lodash';
// utils

// ----------------------------------------------------------------------

const referrals = [...Array(24)].map(() => ({
  referrer: faker.name.findName(),
  user: {
    firstName: faker.name.findName(),
    email: 'support@delightrewards.com'
  },
  referrerId: sample([true, false]),
  createdAt: sample(['Feb 25, 2022', 'Feb 26, 2022', 'Feb 27, 2022', 'Feb 28, 2022'])
}));

export default referrals;
