import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Reviews',
    type: 'line',
    data: [23, 11, 22, 27, 13, 22, 23, 23, 11, 22, 27, 13]
  },
  {
    name: 'Referrals Sent',
    type: 'line',
    data: [44, 55, 41, 67, 22, 43, 21, 44, 55, 41, 67, 22]
  },
  {
    name: 'Referrals Accepted',
    type: 'line',
    data: [22, 25, 21, 27, 22, 23, 21, 22, 25, 21, 27, 22]
  },
  {
    name: 'Rewards',
    type: 'line',
    data: [30, 25, 36, 30, 45, 35, 64, 30, 25, 36, 30, 45]
  }
];

export default function AppWebsiteStats(props) {
  const { chartData } = props;
  const [labels, setLabels] = useState([]);

  const initMonths = () => {
    const months = [];
    const now = new Date();
    now.setDate(1);
    const monthName = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let i = 0;
    while (i <= 11) {
      const current = `01${monthName[now.getMonth()]} ${now.getFullYear()}`;
      months.push(current);
      now.setMonth(now.getMonth() - 1);
      i += 1;
    }
    setLabels(months.reverse());
  };

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [3, 3, 3, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'solid', 'solid'] },
    labels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  useEffect(() => {
    initMonths();
  }, []);

  return (
    <Card>
      <CardHeader title="Monthly Metrics" subheader="" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
