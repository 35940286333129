import { Grid } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import { useTheme } from '@mui/material/styles';

const LoadingTable = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3}>
        <div style={{ height: 40 }} />
      </Grid>
      <Grid item xs={3}>
        <div style={{ height: 40 }} />
      </Grid>
      <Grid item xs={3}>
        <div>
          <TailSpin color={theme.palette.primary.main} height={80} width={80} timeout={0} />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div style={{ height: 80 }} />
      </Grid>
    </Grid>
  );
};

export default LoadingTable;
