/* eslint-disable no-nested-ternary */
// import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import starFilled from '@iconify/icons-ant-design/star-filled';
// material
import {
  Box,
  Button,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import LoadingTable from '../components/LoadingTable';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ReviewListHead,
  ReviewMoreMenu,
} from '../components/_dashboard/review';
import { API_KEY, BACKEND_URL } from '../config/auth';
import { fDateTimeSuffix } from '../utils/formatTime';
//
import REVIEWLIST from '../_mocks_/reviews';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'rating', label: 'Rating', alignRight: false },
  { id: 'comment', label: 'Comment', alignRight: false },
  { id: 'isVerified', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.comment.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const products = localStorage.getItem('delight-dashboard-products');
  const hasReviews = JSON.parse(products)?.reviews;

  const getReviews = async () => {
    try {
      const token = localStorage.getItem('delight-dashboard-token');
      const store = localStorage.getItem('delight-dashboard-store');
      if (token && store) {
        const headers = {
          'Content-type': 'application/json',
          Authorization: `bearer ${API_KEY}`,
          store
        };
        const res = await fetch(
          `${BACKEND_URL}/api/dashboard/reviews/${token}`,
          {
            headers,
            method: 'GET',
          }
        );
        if (res.status === 200) {
          const data = await res.json();
          setReviews(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        window.location.href = '/login';
      }
    } catch (e) {
      setLoading(false);
      console.log('Error', e);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const selected = isLoading ? REVIEWLIST : reviews;
    if (event.target.checked) {
      const newSelecteds = selected.map((n) => n.comment);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, comment) => {
  //   const selectedIndex = selected.indexOf(comment);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, comment);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - isLoading
            ? REVIEWLIST.length
            : reviews.length
        )
      : 0;

  // const filteredReviews = applySortFilter(
  //   isLoading ? REVIEWLIST : reviews,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  const filteredReviews = isLoading ? REVIEWLIST : reviews;

  const isReviewNotFound = filteredReviews.length === 0;

  const renderRating = (rating) => {
    const stars = [];
    for (let s = 0; s < rating; s += 1) {
      stars.push(
        <Icon icon={starFilled} width={16} height={16} color='#fe8520' />
      );
    }

    while (stars.length < 5) {
      stars.push(
        <Icon icon={starFilled} width={16} height={16} color='#cecece' />
      );
    }
    return stars;
  };

  const onReviewAction = async (action, id) => {
    try {
      const token = localStorage.getItem('delight-dashboard-token');
      const store = localStorage.getItem('delight-dashboard-store');
      if (token && store) {
        const headers = {
          'Content-type': 'application/json',
          Authorization: `bearer ${API_KEY}`,
          store,
        };
        const body = JSON.stringify({
          id,
          verified: action,
        });
        const res = await fetch(
          `${BACKEND_URL}/api/dashboard/reviews/${token}`,
          {
            headers,
            body,
            method: 'PUT',
          });
        if (res.status === 200) {
          window.location.reload();
        } else {
          alert(
            `Something went wrong trying to ${
              action ? 'approve' : 'reject'
            } this review. Please try again later or contact support at support@delightrewards.com`
          );
        }
      } else {
        window.location.href = '/login';
      }
    } catch (e) {
      alert(
        `Something went wrong trying to ${
          action ? 'approve' : 'reject'
        } this review. Please try again later or contact support at support@delightrewards.com`
      );
      console.log('Error', e);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <Page title="Reviews | Delight">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Reviews
          </Typography>
          {/* <Button
            variant='contained'
            component={RouterLink}
            to='#'
            startIcon={<Icon icon={plusFill} />}
          >
            New Review
          </Button> */}
        </Stack>

        <Card>
          {/* <ReviewListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          {!hasReviews ? (
            <div
              style={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                alignSelf: 'center',
                flexGrow: 1,
                alignItems: 'center',
                marginBottom: 50
              }}
            >
              <Box
                component="img"
                src="/static/illustrations/reviews-upgrade.svg"
                sx={{ height: 240, mx: 'auto', my: { xs: 5, sm: 5 } }}
              />
              <h2>Need a sales boost?</h2>
              <p
                style={{
                  textAlign: 'center',
                  marginTop: 20,
                  marginBottom: 40,
                  paddingRight: 250,
                  paddingLeft: 250
                }}
              >
                Get 2x the number of reviews thanks to our unique and proven
                approach to receive high quality, verified, and honest reviews.
              </p>
              {/* <Button
                variant='contained'
                onClick={() =>
                  alert(
                    'Please contact sales at support@delightrewards.com, or send a message to 585-615-3693 and we will get back to you ASAP'
                  )
                }
              > */}
              <h3
                style={{
                  textAlign: 'center',
                  marginTop: 20,
                  marginBottom: 40,
                  paddingRight: 250,
                  paddingLeft: 250,
                }}
              >
                Contact support@delightrewards.com to get more reviews
              </h3>
              {/* </Button> */}
            </div>
          ) : isLoading ? (
            <LoadingTable />
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ReviewListHead
                      order={order}
                      // orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={isLoading ? REVIEWLIST.length : reviews.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredReviews
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const {
                            id,
                            user: { firstName: name, email },
                            stars,
                            createdAt,
                            verified,
                            comment
                          } = row;
                          const isItemSelected =
                            selected.indexOf(comment) !== -1;
                          const status = verified ? 'active' : 'inactive';
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, comment)}
                            /> */}
                              </TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left" width="12%">
                                {renderRating(stars)}
                              </TableCell>
                              <TableCell align="left">{comment}</TableCell>
                              <TableCell align="left" width="12%">
                                {fDateTimeSuffix(createdAt)}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={
                                    (status === 'inactive' && 'error') ||
                                    'success'
                                  }
                                >
                                  {sentenceCase(status)}
                                </Label>
                              </TableCell>

                              <TableCell align='right'>
                                <ReviewMoreMenu
                                  status={verified}
                                  id={id}
                                  onReviewAction={onReviewAction}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isReviewNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={isLoading ? REVIEWLIST.length : reviews.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
