import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgProduct } from '../utils/mockImages';

// ----------------------------------------------------------------------

const reviews = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  reward: {
    image: mockImgProduct(index + 1),
    title: sample(['$10 Gift Card', 'Tea Bag', 'Magic Shoes'])
  },
  points: sample([500, 1000]),
  type: sample(['physical', 'virtual']),
  user: {
    firstName: faker.name.findName(),
    email: 'support@delightrewards.com'
  },
  createdAt: sample(['Feb 25, 2022', 'Feb 26, 2022', 'Feb 27, 2022', 'Feb 28, 2022']),
  rewardStatus: sample(['fulfilled', 'pending'])
}));

export default reviews;
