import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function ReviewMoreMenu(props) {
  const { status, id, onReviewAction } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon
          color={!status ? 'error.main' : 'success.main'}
          icon={!status ? checkCircle : closeCircle}
          width={20}
          height={20}
        />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!status ? (
          <MenuItem sx={{ color: 'success.main' }} onClick={() => onReviewAction(true, id)}>
            <ListItemIcon>
              <Icon icon={checkCircle} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Approve" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'error.main' }}
            onClick={() => onReviewAction(false, id)}
          >
            <ListItemIcon>
              <Icon icon={closeCircle} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Remove" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
