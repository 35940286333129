import PropTypes from 'prop-types';
// material
import { Box, Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

NoTableData.propTypes = {
  type: PropTypes.string
};

export default function NoTableData({ type = '' }) {
  return (
    <Paper>
      <Box
        component="img"
        src="/static/illustrations/no-data.svg"
        sx={{ height: 150, mx: 'auto', my: { xs: 5, sm: 5 } }}
      />
      <Typography gutterBottom align="center" variant="subtitle1">
        No results found
      </Typography>
      <Typography variant="body2" align="center">
        We couldn't find {type} data
      </Typography>
    </Paper>
  );
}
