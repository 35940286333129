import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import starFill from "@iconify/icons-eva/star-fill";
import heartFilled from "@iconify/icons-ant-design/heart-filled";
import giftFilled from "@iconify/icons-ant-design/gift-filled";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import emailFill from "@iconify/icons-ant-design/mail-filled";
import settingFilled from '@iconify/icons-ant-design/setting-filled';
// import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "rewards redeemed",
    path: "/dashboard/redeem",
    icon: getIcon(shoppingBagFill),
  },
  {
    title: "reviews",
    path: "/dashboard/reviews",
    icon: getIcon(starFill),
  },
  {
    title: "referrals",
    path: "/dashboard/referrals",
    icon: getIcon(heartFilled),
  },
  // {
  //   title: 'emails',
  //   path: '/dashboard/emails',
  //   icon: getIcon(emailFill)
  // },
  {
    title: "Rewards settings",
    path: "/dashboard/rewards",
    icon: getIcon(giftFilled),
  },
  {
    title: "Store settings",
    path: "/dashboard/settings",
    icon: getIcon(settingFilled),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
