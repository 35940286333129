import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { fCurrency } from "../../../utils/formatNumber";
import { API_KEY, BACKEND_URL } from "../../../config/auth";

const RewardImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

RewardCard.propTypes = {
  reward: PropTypes.object,
  hasDirectRewards: PropTypes.bool,
};

export default function RewardCard({ reward, hasDirectRewards }) {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const { title, image, points, value, id } = reward;
  const [rewardStatus, setRewardStatus] = useState(true);
  const key = localStorage.getItem("delight-dashboard-store");

  const handleRewardSwitchChange = (event) => {
    const status = event.target.checked;
    setRewardStatus(status);
    makeRewardRequest(key, status);
  };

  const makeRewardRequest = (key, status) => {
    const requestBody = JSON.stringify({
      key,
      hidden: !status,
    });

    fetch(`${BACKEND_URL}/api/dashboard/update-reward/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success");
      })
      .catch((error) => {
        console.log("Error", Error);
      });
  };

  useEffect(() => {
    const requestBody = JSON.stringify({
      key,
    });

    fetch(`${BACKEND_URL}/api/dashboard/get-store-reward/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        setRewardStatus(!data.hidden);
        console.log("Success");
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }, []);

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <RewardImgStyle
          alt={title}
          src={image}
          style={{ objectFit: "contain" }}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {hasDirectRewards ? (
            <></>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{
                color: "#fe8520",
              }}
            >
              {points} points
            </Typography>
          )}

          {value && value > 0 && (
            <Typography variant="subtitle1">
              &nbsp;
              {hasDirectRewards ? "Value: " : ""}
              {fCurrency(value)}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <Switch
            {...label}
            onChange={handleRewardSwitchChange}
            checked={rewardStatus}
          />
        </Stack>
      </Stack>
    </Card>
  );
}
